<template>
  <b-form-group
    :label="label"
    :label-for="`select-specifications-${id}`"
    :description="showDescription ? description : ''"
  >
    <b-form-select
      v-if="specifications_status === apiStatus.LOADED"
      :id="`select-specifications-${id}`"
      :value="value"
      :options="showAllSpecificationsOption ? specifications_options_with_all : specifications_options"
      @change="selected => $emit('input', selected)"
    />
    <b-overlay
      v-else
      show
      opacity="0.1"
    >
      <template #overlay>
        <div style="width: 100%">
          Loading Specifications...
        </div>
      </template>
      <b-form-select />
    </b-overlay>
  </b-form-group>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { apiStatus } from '@/enums'

export default {
  name: 'SelectSpecifications',
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Specifications:',
    },
    showAllSpecificationsOption: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: Math.random(),
      apiStatus,
    }
  },
  computed: {
    ...mapGetters({
      specifications_options: 'specifications/specifications_options',
    }),
    ...mapState({
      specifications_status: state => state.specifications.specifications_status,
    }),
    specifications_options_with_all() {
      return [
        ...this.specifications_options, {
          text: '-- All Specifications --',
          value: null,
          default: false,
          additional_attributes: [],
          description: '',
        }]
    },
    description() {
      const selectedSpecification = this.specifications_options.find(({ value }) => value === this.value)
      if (!selectedSpecification) { return '' }
      return 'description' in selectedSpecification ? selectedSpecification.description : ''
    },
  },
  watch: {
    specifications_options: {
      immediate: true,
      handler() {
        this.$emit('input', this.showAllSpecificationsOption ? null : this.specifications_options.find(specification => specification.default).value)
      },
    },
  },
}
</script>
