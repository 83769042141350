<template>
  <b-modal
    v-if="selected_entity && selected_entity.context"
    id="merge-entity-modal"
    :title="`Merge ${selected_entity.context.details.name}`"
    size="xl"
    class="p-0"
    @show="onShow"
    @ok="mergeEntity()"
  >
    <div>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Select Entity to Merge with:" label-for="parent-select">
            <b-form-select
              id="parent-select"
              v-model="selected_parent"
              :options="parent_opts"
              :select-size="7"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <template v-slot:modal-footer="{ ok, cancel}">
      <b-button variant="primary" @click="ok()">
        Merge
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MergeEntityModal',

  data() {
    return {
      selected_parent: false,
    }
  },
  computed: {
    ...mapState({
      selected_entity: state => state.domainModel.selected_entity2,
      components: state => state.domainModel.components,
    }),
    parent_opts() {
      return this.components.filter(cpt => cpt.id !== this.selected_entity.context.details.id).map(x => ({ value: x.id, text: x.qualified_name }))
    },
  },
  methods: {
    onShow() {
      this.$store.dispatch('domainModel/getComponents')
    },
    mergeEntity() {
      const vueApp = this
      console.debug('Merging ', vueApp.selected_entity, ' into ', vueApp.selected_parent)
      this.$store.dispatch('domainModel/mergeComponent', {
        from: vueApp.selected_entity.context.details.id,
        to: vueApp.selected_parent,
      })
        .then(() => {
          vueApp.$store.dispatch('domainModel/selectEntity2', vueApp.selected_parent)
            .then(() => {
              this.$emit('merged', vueApp.selected_parent)
            })
        })
    },
  },
}
</script>
