<template>
  <div>
    <div class="d-inline-flex w-100 justify-content-between pl-1">
      <h6>Allocation for <span class="text-primary">{{ name ? name : 'Function' }}</span></h6>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-primary"
        class="float-left"
        size="sm"
        @click="routeToOntology"
      >
        View/Edit in Ontology
      </b-button>
    </div>

    <hr>
    <div>
      <list-group-entity
        label="Performers"
        :entity-array="fields.selected_performers"
        modal="allocate-fn-performers-modal"
        class="mb-50"
      />
      <list-group-entity
        label="Enablers"
        :entity-array="fields.selected_enablers"
        modal="allocate-fn-enablers-modal"
        class="mb-50"
      />
      <list-group-entity
        label="Beneficiaries"
        :entity-array="fields.selected_beneficiaries"
        modal="allocate-fn-beneficiaries-modal"
        class="mb-50"
      />
      <list-group-entity
        label="Objectives"
        :entity-array="fields.selected_objectives"
        modal="allocate-fn-objectives-modal"
        class="mb-50"
      />
      <list-group-entity
        label="Standards/References"
        :entity-array="fields.selected_standards"
        modal="allocate-fn-standards-modal"
        class="mb-50"
      />
      <list-group-entity
        label=" - Other Allocations"
        :entity-array="fields.selected_allocations"
        modal="allocate-fn-allocations-modal"
        class="mb-50"
      />
      <list-group-releases
        label="Releases"
        :entity-array="fields.selected_releases"
        modal="allocate-fn-releases-modal"
        class="mb-50"
      />
      <list-group-requirements
        label="Requirements"
        :entity-array="fields.selected_requirements"
        modal="allocate-fn-requirement-modal"
      />
    </div>

    <div id="allocation-modals" class="hidden">
      <div v-for="assc in associators" :key="assc.singular">
        <GenericAssociator
          :id="`allocate-fn-${getPlural(assc)}-modal`"
          :fetch-filter-fn="fetchSharedModels"
          :fetch-fn="modelId => fetchComponents(modelId, `${getLeftOpts(assc)}`)"
          :update-fn="payload => linkComponents(payload, getPlural(assc))"
          :initial-prefilter="selectedModelComputed"
          :initial-list="fields[`selected_${getPlural(assc)}`]"
          :instant-save="false"
          prefilter-label="name"
          :type-name="assc.label"
        >
          <template #left="cpt">
            <b-badge v-for="l in cpt.labels.filter(lb => lb !== 'Component')" :key="l" class="mr-1" variant="primary">
              ({{ l }})
            </b-badge>
            &nbsp;
            <abbr :title="cpt.name" class="mr-1 text-ellipsis">{{ cpt.name }}</abbr>
          </template>
          <template #right="{ cpt, triggerSave }">
            <ComponentAllocatedListItem :component-badge="assc.singular" :component="cpt"
                                        :trigger-fn="triggerSave"
            />
          </template>
        </GenericAssociator>
      </div>
      <Associator
        id="allocate-fn-requirement-modal"
        title="Associate Function with Requirements"
        left-label="Requirements"
        :left-opts="allReqs.map(x=>{return{value:x.id, text:x.display_id+' - '+x.text, display_id: x.display_id, object_text: x.text, priority: x.priority}})"
        right-label="Requirements"
        :right-opts="fields.selected_requirements.map(x=>{return{value:x.id, text:x.display_id+'-'+x.object_text, display_id: x.display_id, object_text: x.object_text, priority: x.priority}})"
        @ok="linkFnRequirements"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import Associator from '@/components/Forms/M_Associator.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import ListGroupReleases from '@/components/Forms/ListGroups/ListGroupRelease.vue'
import ListGroupRequirements from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import coreService from '@/libs/api-services/core-service'
import GenericAssociator from '@/components/Forms/GenericAssociator.vue'
import ComponentAllocatedListItem from '@/components/Forms/ComponentAllocatedListItem.vue'

export default {
  components: {
    ComponentAllocatedListItem,
    GenericAssociator,
    BButton,
    Associator,
    ListGroupEntity,
    ListGroupReleases,
    ListGroupRequirements,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allModels: [],
      selectedModel: null,
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      description: '',
      validity: 'Valid',
      context_data: { attributes: {} },
      allReqs: [],
      associators: [
        { singular: 'Performer', label: 'Actors/Performers' },
        { singular: 'Enabler', label: 'Enablers' },
        {
          singular: 'Beneficiary',
          label: 'Beneficiaries',
          plural: 'beneficiaries',
          leftOpts: 'performers',
        },
        { singular: 'Objective', label: 'Objectives' },
        { singular: 'Standard', label: 'Related Standards/References' },
        { singular: 'Release', label: 'Releases' },
        {
          singular: 'Allocation',
          label: 'Other Allocations',
          plural: 'allocations',
          leftOpts: '',
        },
      ],
      fields: {
        selected_performers: [],
        selected_objectives: [],
        selected_standards: [],
        selected_enablers: [],
        selected_beneficiaries: [],
        selected_allocations: [],
        selected_releases: [],
        selected_requirements: [],
        selected_interfaces: [],
      },
      quill_editor_options: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar',
        },
      },
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
    ...mapState({
      performers: state => state.domainModel.performers,
      components: state => state.domainModel.components,
      functions: state => state.domainModel.functions,
      objectives: state => state.domainModel.objectives,
      standards: state => state.domainModel.standards,
      releases: state => state.releases.releases,
      selectedModelComputed: state => state.model,
    }),
    ...mapGetters({
      stereotypes: 'constants/stereotypes',
    }),
    perfplus() {
      const therest = this.components.filter(x => !(this.performers.map(p => p.id).includes(x.id)))
      const sep = [
        {
          id: null,
          name: '--------------',
        },
      ]
      return [...this.performers, ...sep, ...therest]
    },
  },
  watch: {
    selected_entity2(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.$store.dispatch('domainModel/getComponents')
    this.$store.dispatch('releases/getReleases')
    this.$store.dispatch('interfaces/fetchInterfaces')
    this.fillFields(this.selected_entity2)
    this.populate()
  },
  methods: {
    getPlural(obj) {
      return obj?.plural || `${obj.singular.toLowerCase()}s`
    },
    getLeftOpts(obj) {
      return obj?.leftOpts === '' ? '' : obj?.leftOpts || this.getPlural(obj)
    },
    populate() {
      const modelId = this.$store.state.model.id
      this.$http.get('/api/v2/requirements/get_requirements_simple', { params: { model: modelId } })
        .then(({ data }) => {
          this.allReqs = data
        })
    },
    fillFields(n) {
      const con = n.context
      this.name = con.details.name
      this.acronym = con.details.acronym
      this.description = con.details.description
      this.stereotype_selected = con.labels
      this.abstract = con.details.abstract === true
      this.multiplicity = con.details.multiplicity
      this.validity = con.details.validity
      this.id = con.details.id
      const t = this.fields
      t.selected_performers = con.relations.edges.filter(e => e.name === 'Performs')
        .map(x => ({ id: x.source, name: x.source_str, justification: x.properties.justification }))
      t.selected_beneficiaries = con.relations.edges.filter(e => e.name === 'Benefits')
        .map(x => ({ id: x.target, name: x.target_str, justification: x.properties.justification }))
      t.selected_enablers = con.relations.edges.filter(e => e.name === 'Enabled by')
        .map(x => ({ id: x.target, name: x.target_str, justification: x.properties.justification }))
      t.selected_objectives = con.relations.edges.filter(e => e.name === 'Contributes to')
        .map(x => ({ id: x.target, name: x.target_str, justification: x.properties.justification }))
      t.selected_standards = con.relations.edges.filter(e => e.name === 'Performed iaw')
        .map(x => ({ id: x.target, name: x.target_str, justification: x.properties.justification }))
      t.selected_allocations = con.relations.edges.filter(e => e.name === 'Allocated to')
        .map(x => ({ id: x.target, name: x.target_str, justification: x.properties.justification }))
      t.selected_releases = con.relationships.filter(
        e => e.rel_type === 'ALLOCATED_TO' && e.labels && e.labels.includes('Release'),
      ).map(
        x => ({ id: x.target_props.id, name: x.target_props.name }),
      )
      t.selected_requirements = con.relationships.filter(
        e => e.labels && e.labels.includes('Requirement'),
      ).map(
        x => (x.target_props),
      )
      t.selected_interfaces = con.relationships.filter(
        e => e.rel_type === 'ALLOCATED_TO' && e.labels && e.labels.includes('Interface'),
      ).map(
        x => ({ id: x.source_props.id, name: x.source_props.name }),
      )
    },
    linkFnPerformers(evt, objs) {
      evt.preventDefault()
      this.fields.selected_performers = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.allocateFn()
    },
    async fetchSharedModels() {
      return [this.$store.state.model, ...await coreService.modelApi.getLinkedModels(this.$store.state.model.id)]
    },
    async fetchComponents(modelId, subtype) {
      await this.$store.dispatch('domainModel/getComponentsForModel', { modelId, subtype: '' })
      if (modelId && this.$store.state.model.id !== modelId) {
        // now returns all components, not just the subtype
        return this.$store.state.domainModel[modelId].components
      }
      return this.$store.state.domainModel.components
    },
    async linkComponents(payload, allocationType) {
      this.fields[`selected_${allocationType}`] = payload.map(x => ({
        id: x.id,
        name: x.name,
        justification: x.justification,
      }))
      this.allocateFn()
    },
    linkFnEnablers(evt, objs) {
      evt.preventDefault()
      this.fields.selected_enablers = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.allocateFn()
    },
    linkFnBeneficiaries(evt, objs) {
      evt.preventDefault()
      this.fields.selected_beneficiaries = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.allocateFn()
    },
    linkFnObjectives(evt, objs) {
      evt.preventDefault()
      this.fields.selected_objectives = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.allocateFn()
    },
    linkFnStandards(evt, objs) {
      evt.preventDefault()
      this.fields.selected_standards = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.allocateFn()
    },
    linkFnAllocations(evt, objs) {
      evt.preventDefault()
      this.fields.selected_allocations = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.allocateFn()
    },
    linkFnReleases(evt, objs) {
      evt.preventDefault()
      this.fields.selected_releases = objs.map(x => ({
        id: x.value,
        name: x.text,
      }))
      this.allocateFn()
    },
    linkFnRequirements(evt, objs) {
      evt.preventDefault()
      this.fields.selected_requirements = objs.map(x => ({
        id: x.value,
        display_id: x.display_id,
        priority: x.priority,
        object_text: x.object_text,
      }))
      this.allocateFn()
    },
    allocateFn() {
      const mapper = x => ({ id: x.id, justification: x.justification })
      this.$store.dispatch('domainModel/allocateFunction', {
        function: this.id,
        performers: this.fields.selected_performers.map(mapper),
        enablers: this.fields.selected_enablers.map(mapper),
        beneficiaries: this.fields.selected_beneficiaries.map(mapper),
        objectives: this.fields.selected_objectives.map(mapper),
        standards: this.fields.selected_standards.map(mapper),
        other: this.fields.selected_allocations.map(mapper),
        releases: this.fields.selected_releases.map(mapper),
        requirements: this.fields.selected_requirements.map(x => x.id),
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.name} Allocated`,
            icon: 'CheckIcon',
            text: 'Functional Allocation Performed',
            variant: 'success',
          },
        })
        this.$bvModal.hide('allocate-function-modal')
      })
    },
    routeToOntology(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: this.id },
        },
      )
    },
    deselectPerformers() {
      this.fields.selected_performers = []
    },
  },
}
</script>
