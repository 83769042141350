<template>
  <div class="mx-1">
    <h4 class="mb-2">
      Function Constraints and Qualifiers for
      <b>{{ name ? name : 'Entity' }}</b>
    </h4>

    <!-- Performer Constraints -->
    <b-form-group>
      <div class="mb-1 d-inline w-100 justify-content-between">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          title="Add new row"
          @click="repeatConstraint"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Add
        </b-button>
        <span class="ml-50 align-middle">
          Constraints on Performers
        </span>
      </div>

      <div
        v-for="(item, index) in perfConstraints"
        :id="item.id"
        :key="item.id"
        class="d-inline-flex w-100 mt-50"
      >
        <b-form-select
          v-model="item.performer"
          :options="performers"
          value-field="id"
          text-field="name"
          class="mr-1"
        />
        <b-form-input
          id="constraint-input"
          v-model="item.name"
          type="text"
          placeholder="e.g. authorised as per Roles Matrix"
        />
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          size="sm"
          variant="flat-danger"
          title="Remove Constraint"
          @click="removeConstraint(index)"
        >
          <feather-icon icon="TrashIcon" class="text-danger" />
        </b-button>
      </div>
    </b-form-group>

    <hr class="mt-3">

    <!-- Function Constraints -->
    <b-form-group>
      <div class="mb-1 d-inline w-100 justify-content-between">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          title="Add new row"
          @click="repeatFnConstraint"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Add
        </b-button>
        <span class="ml-50 align-middle">
          Constraints on the Action
        </span>
      </div>

      <div
        v-for="(item, index) in fnConstraints"
        :id="item.id"
        :key="item.id"
        class="d-inline-flex w-100 mt-50"
      >
        <b-form-input
          id="fn-constraint-input"
          v-model="item.name"
          type="text"
          placeholder="e.g. in under 3 seconds of receipt"
        />

        <!-- Remove Button -->
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          size="sm"
          variant="flat-danger"
          title="Remove Constraint"
          @click="removeFnConstraint(index)"
        >
          <feather-icon icon="TrashIcon" class="text-danger" />
        </b-button>
      </div>
    </b-form-group>

    <hr class="mt-3">

    <!-- Object Constraints -->
    <b-form-group>
      <div class="mb-1 d-inline w-100 justify-content-between">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          title="Add new row"
          @click="repeatObjConstraint"
        >
          <feather-icon
            icon="PlusIcon"
          />
          Add
        </b-button>
        <span class="ml-50 align-middle">
          Constraints on Objects of the Action
        </span>
      </div>

      <div
        v-for="(item, index) in objectConstraints"
        :id="item.id"
        :key="item.id"
        class="d-inline-flex w-100 mt-50"
      >
        <b-form-select
          v-model="item.object"
          :options="objects"
          value-field="id"
          text-field="name"
          class="mr-1"
        />
        <b-form-input
          id="obj-constraint-input"
          v-model="item.name"
          type="text"
          placeholder="e.g. approved"
        />
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          size="sm"
          variant="flat-danger"
          title="Remove Constraint"
          @click="removeObjConstraint(index)"
        >
          <feather-icon icon="TrashIcon" class="text-danger" />
        </b-button>
      </div>
    </b-form-group>

    <hr class="mt-3">

    <div class="d-inline-flex w-100 justify-content-between">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-info"
        @click="routeToOntology"
      >
        Show in Ontology
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        @click="constrainFn"
      >
        Save changes
      </b-button>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: { Ripple },
  data() {
    return {
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      description: '',
      validity: 'Valid',
      context_data: { attributes: {} },
      allReqs: [],
      perfConstraints: [],
      nextPerfConstraintId: 0,
      fnConstraints: [],
      nextFnConstraintId: 0,
      stdFnConstraints: [],
      nextStdFnConstraintId: 0,
      objectConstraints: {},
      nextObjectConstraintId: 0,
      performers: [],
      objects: [],

      quill_editor_options: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar',
        },
      },
    }
  },
  computed: {
    ...mapState('domainModel', ['selected_entity2']),
  },
  watch: {
    selected_entity2(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.fillFields(this.selected_entity2)
  },
  methods: {
    fillFields(n) {
      const con = n.context
      this.name = con.details.name
      this.acronym = con.details.acronym
      this.description = con.details.description
      this.stereotype_selected = con.labels
      this.abstract = con.details.abstract === true
      this.multiplicity = con.details.multiplicity
      this.validity = con.details.validity
      this.id = con.details.id
      this.performers = con.relations.edges.filter(e => e.name === 'Performs').map(x => ({
        id: x.source,
        name: x.source_str,
      }))
      this.perfConstraints = con.relationships.filter(
        e => e.rel_type === 'CONSTRAINED_BY' && e.labels && e.labels.includes('PerformerConstraint'),
      ).map(
        x => ({ id: x.target_props.id, name: x.target_props.name, performer: x.target_props.performer }),
      )
      this.nextPerfConstraintId = this.perfConstraints.length
      this.fnConstraints = con.relationships.filter(
        e => e.rel_type === 'CONSTRAINED_BY' && e.labels && e.labels.includes('ActionConstraint'),
      ).map(
        x => ({ id: x.target_props.id, name: x.target_props.name }),
      )
      this.nextFnConstraintId = this.fnConstraints.length
      this.objectConstraints = con.relationships.filter(
        e => e.rel_type === 'CONSTRAINED_BY' && e.labels && e.labels.includes('ObjectConstraint'),
      ).map(
        x => ({ id: x.target_props.id, name: x.target_props.name, object: x.target_props.object }),
      )
      this.objects = con.relations.edges.filter(e => e.name === 'Uses').map(x => ({
        id: x.target,
        name: x.target_str,
      }))
      this.standards = con.relations.edges.filter(e => e.name === 'Performed iaw').map(x => ({
        id: x.target,
        name: x.target_str,
      }))
      this.stdFnConstraints = con.relations.edges.filter(e => e.name === 'Performed iaw').map(x => ({
        id: x.target,
        name: `performed in accordance with ${x.target_str}`,
      }))
      this.fnConstraints = [...this.fnConstraints, ...this.stdFnConstraints]
    },

    constrainFn() {
      this.$store.dispatch('domainModel/constrainFunction', {
        function: this.id,
        perfConstraints: this.perfConstraints,
        objectConstraints: this.objectConstraints,
        actionConstraints: this.fnConstraints,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Constraints defined for ${this.name}`,
            icon: 'CheckIcon',
            text: 'Functional Constraints Defined',
            variant: 'success',
          },
        })
      })
    },

    // Form Repeater methods
    repeatConstraint() {
      this.perfConstraints.push({
        id: this.nextPerfConstraintId += 1,
        performer: this.performers.length > 0 ? this.performers[0] : null,
      })
    },
    removeConstraint(index) {
      this.perfConstraints.splice(index, 1)
    },
    repeatFnConstraint() {
      this.fnConstraints.push({
        id: this.nextFnConstraintId += 1,
      })
    },
    removeFnConstraint(index) {
      this.fnConstraints.splice(index, 1)
    },
    repeatObjConstraint() {
      this.objectConstraints.push({
        id: this.nextObjectConstraintId += 1,
        object: this.objects.length > 0 ? this.objects[0] : null,
      })
    },
    removeObjConstraint(index) {
      this.objectConstraints.splice(index, 1)
    },
    routeToOntology(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: this.id },
        },
      )
    },
  },
}
</script>
